
import { defineComponent, toRefs, ref, watch, onMounted } from 'vue'
import pageData from './pageData'
import dayjs, { Dayjs } from 'dayjs'
import echart from '@/components/echarts/echarts.vue'
import Data from '@/model/common/Data'
import homeApi from '@/axios/api/index'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { debounce } from 'lodash-es'
export default defineComponent({
  components: {
    echart,
  },
  setup() {
    function replaceTime(data: number): string {
      const date = new Date(data)
      const Y = date.getFullYear() + '-'
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + ' ' + h + m + s
    }
    let store = useStore()
    let end = replaceTime(new Date().getTime())
    let start = replaceTime(new Date().getTime() - 14 * 3600 * 24 * 1000)
    pageData.selectDate[0] = start
    pageData.selectDate[1] = end
    pageData.updateTime = end
    const selectDemand = () => {
      pageData.updateTime = replaceTime(new Date().getTime())
      homeApi
        .GetIndexDemand({
          begin: pageData.selectDate[0],
          end: pageData.selectDate[1],
        })
        .then((res) => {
          pageData.pieData = res.map as Data
          pageData.statusList.forEach((item) => {
            item.value = res[item.id] as number
          })
          //从接口获取横坐标数据
          let keys = Object.keys(pageData.pieData)
          keys.sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime()
          })
          pageData.options.xAxis.data = keys
          //遍历六条折线数据拿到折线的index
          pageData.options.series.forEach((item, index) => {
            //把所有折线的数据赋值为空
            pageData.options.series[index].data = []
            //再遍历获取的每一项数据 添加到折线图的数据data中
            keys.forEach((samllItem) => {
              if ((pageData.pieData[samllItem] as Data)[item.id] !== null) {
                pageData.options.series[index].data.push(
                  (pageData.pieData[samllItem] as Data)[item.id] as number
                )
              }
            })
          })
        })
        .catch((err) => {
          message.error(err || '接口请求错误')
        })
    }
    const getData = () => {
      if (store.state.token) {
        selectDemand()
        homeApi
          .GetIndexProduct()
          .then((res) => {
            pageData.barOptions.series.data[0] = res.fillCount as number
            pageData.barOptions.series.data[1] = res.productCount as number
            pageData.productData = res
          })
          .catch((err) => {
            message.error(err)
          })
        homeApi
          .GetIndexCustomer()
          .then((res) => {
            pageData.customerData = res
            pageData.pieOptions.series.data.forEach((item) => {
              item.value = res[item.id] as number
            })
          })
          .catch((err) => {
            message.error(err)
          })
        homeApi
          .GetIndexCount()
          .then((res) => {
            pageData.headerData = res as Data
            pageData.topList.forEach((item) => {
              if (item.title === '需求数量') {
                item.value = pageData.headerData.demandCount as number
                item.promote = pageData.headerData.demandRate as number
              } else if (item.title === '成果数量') {
                item.value = pageData.headerData.resultCount as number
                item.promote = pageData.headerData.resultRate as number
              } else if (item.title === '竞品数量') {
                item.value = pageData.headerData.productCount as number
                item.promote = pageData.headerData.productRate as number
              } else if (item.title === '客户数量') {
                item.value = pageData.headerData.customerCount as number
                item.promote = pageData.headerData.customerRate as number
              }
            })
          })
          .catch((err) => {
            console.log(err)
            message.error(err)
          })
      }
    }
    getData()
    watch(
      () => store.state.token,
      (newval, oldVal) => {
        if (newval && oldVal === '') {
          getData()
        }
      }
    )

    const dates = ref<Dayjs[]>([])
    //控制可选择的时间
    const disabledDate = (current: Dayjs) => {
      //如果未选择时间 则禁用未来时间
      if (!dates.value || dates.value.length === 0) {
        return current && current > dayjs().endOf('day')
      }
      //选择时间后禁用超过30天的时间和未来时间的时间
      const diffDate = current.diff(dates.value[0], 'days')
      return (
        Math.abs(diffDate) > 29 ||
        (current && current > dayjs().endOf('day')) ||
        Math.abs(diffDate) < 1
      )
    }
    //日期选择变化时  把选择的时间赋值给dates 用来控制时间禁用
    const onCalendarChange = (val: Dayjs[]) => {
      dates.value = val
    }
    //打开日期选择面板时候 清除选择的时间
    const onOpenChange = (open: boolean) => {
      if (open) {
        dates.value = []
      }
    }
    //获取echarts组件实例
    const barChart = ref()
    const pieChart = ref()
    const echart = ref()
    onMounted(() => {
      //监听缩放来重绘echarts
      window.onresize = debounce(function () {
        //调用echarts组件实例方法
        echart.value.resize()
        pieChart.value.resize()
        barChart.value.resize()
        console.log('缩放了', barChart.value)
      }, 500)
    })
    type lineType = '待分配' | '评估中' | '开发中' | '报价中' | '已完成' | '已终止'
    type pieType = '上市公司' | '非上市公司' | 'IPO公司'
    type barType = '填报信息数量' | '竞品数量'
    const close = (key: 'options' | 'pieOptions' | 'barOptions', item: Data) => {
      if (key === 'options') {
        pageData[key].legend.selected[item.title as lineType] =
          !pageData[key].legend.selected[item.title as lineType] //取反
        item.isShow = pageData[key].legend.selected[item.title as lineType] //给标题选项赋值来改变字体颜色
      } else if (key === 'pieOptions') {
        pageData[key].legend.selected[item.title as pieType] =
          !pageData[key].legend.selected[item.title as pieType]
        item.isShow = pageData[key].legend.selected[item.title as pieType]
      } else if (key === 'barOptions') {
        pageData[key].legend.selected[item.title as barType] =
          !pageData[key].legend.selected[item.title as barType]
        item.isShow = pageData[key].legend.selected[item.title as barType]
      }
    }
    return {
      ...toRefs(pageData),
      disabledDate,
      onCalendarChange,
      onOpenChange,
      selectDemand,
      barChart,
      pieChart,
      echart,
      close,
    }
  },
})
