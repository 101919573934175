import Data from '@/model/common/Data'
// import {
//   CompetingGoods,
//   ReqCompetingGoods,
// } from '@/model/competitiveAnalysis/competingGoodsData/competingGoods'
import { reactive } from 'vue'
const pageData = reactive({
  topList: [
    {
      title: '需求数量',
      value: 0,
      promote: 0,
      bgi: require('@/assets/bgi/needNo.png'),
    },
    {
      title: '成果数量',
      value: 0,
      promote: 0,
      bgi: require('@/assets/bgi/fillInNo.png'),
    },
    {
      title: '竞品数量',
      value: 0,
      promote: 0,
      bgi: require('@/assets/bgi/competitiveProductNo.png'),
    },
    {
      title: '客户数量',
      value: 0,
      promote: 0,
      bgi: require('@/assets/bgi/customerNo.png'),
    },
  ],
  editList: [
    {
      icon: 'icon-xinjiantonghang',
      title: '新建同行',
      path: '/competitiveAnalysis/peerData/addPeerData',
    },
    { icon: 'icon-wuliaotianbao', title: '物料填报', path: '/competitiveAnalysis/fillInformation' },
    { icon: 'icon-zengjiaxuqiu', title: '增加需求', path: '/industryManagement/demandAdd' },
    {
      icon: 'icon-chaxunkehuxinxi',
      title: '查询客户信息',
      path: '/customerManagement/customerInformation',
    },
    {
      icon: 'icon-chaxunxuqiuchengguo',
      title: '查询需求成果',
      path: '/industryManagement/resultsManagement',
    },
    {
      icon: 'icon-chaxunjingpinxinxi',
      title: '查询竞品信息',
      path: '/competitiveAnalysis/competingGoodsData',
    },
  ],
  selectDate: ['2022-03-06 15:47:53', '2022-03-20 15:47:53'],
  statusList: [
    {
      borderColor: '#13c2c2',
      title: '待分配',
      value: 0,
      id: 'statusZeroCount',
    },
    {
      borderColor: '#9486fd',
      title: '评估中',
      value: 0,
      id: 'statusOneCount',
    },
    {
      borderColor: '#41a3fd',
      title: '开发中',
      value: 0,
      id: 'statusTwoCount',
    },
    {
      borderColor: '#ff972f',
      title: '报价中',
      value: 0,
      id: 'statusThreeCount',
    },
    {
      borderColor: '#425ed2',
      title: '已完成',
      value: 0,
      id: 'statusFourCount',
    },
    {
      borderColor: '#f96868',
      title: '已终止',
      value: 0,
      id: 'statusFiveCount',
    },
  ],
  options: {
    color: ['#13c2c2', '#9486fd', '#41a3fd', '#ff972f', '#425ed2', '#f96868'],
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['待分配', '评估中', '开发中', '报价中', '已完成', '已终止'],
      right: '300px',
      padding: [0, 100, 100, 100],
      show: false,
      selected: {
        待分配: true,
        评估中: true,
        开发中: true,
        报价中: true,
        已完成: true,
        已终止: true,
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [] as string[],
    },
    yAxis: {
      type: 'value',
      // data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
    },
    series: [
      {
        name: '待分配',
        id: 'statusZeroCount',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: '评估中',
        id: 'statusOneCount',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: '开发中',
        id: 'statusTwoCount',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: '报价中',
        id: 'statusThreeCount',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: '已完成',
        id: 'statusFourCount',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: '已终止',
        id: 'statusFiveCount',
        type: 'line',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  },
  pieOptions: {
    tooltip: {
      trigger: 'item',
    },
    color: ['#13c2c2', '#ff972f', '#41a3fd'],
    legend: {
      data: ['上市公司', '非上市公司', 'IPO公司'],
      show: false,
      selected: {
        上市公司: true,
        非上市公司: true,
        IPO公司: true,
      },
    },
    series: {
      name: '占比图',
      type: 'pie',
      radius: ['40%', '70%'],
      top: '36px',
      avoidLabelOverlap: true,
      label: {
        show: true,
        position: 'outside',
        formatter: '{b}: {d}%',
      },
      labelLine: {
        show: true,
        showAbove: true,
      },
      data: [
        { value: 0, name: '上市公司', id: 'isMarketCount' },
        { value: 0, name: '非上市公司', id: 'noMarketCount' },
        { value: 0, name: 'IPO公司', id: 'ipoCount' },
      ],
    },
  },
  barOptions: {
    xAxis: {
      type: 'category',
      data: ['填报信息数量', '竞品数量'],
    },
    legend: {
      data: ['填报信息数量', '竞品数量'],
      show: false,
      selected: {
        填报信息数量: false,
        竞品数量: true,
      },
    },
    yAxis: {
      type: 'value',
    },
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '15%',
    },
    series: {
      data: [0, 0],
      type: 'bar',
      itemStyle: {
        normal: {
          //这里是重点
          color: function (params: Data) {
            //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
            const colorList = ['#13c2c2', '#41a3fd']
            return colorList[params.dataIndex as number]
          },
        },
      },
    },
  },
  pieData: {
    '2022-02-16': {
      statusZeroCount: 0,
      statusOneCount: 0,
      statusTwoCount: 0,
      statusThreeCount: 0,
      statusFourCount: 0,
      statusFiveCount: 0,
      begin: null,
      end: null,
    },
    '2022-02-15': {
      statusZeroCount: 0,
      statusOneCount: 0,
      statusTwoCount: 0,
      statusThreeCount: 0,
      statusFourCount: 0,
      statusFiveCount: 0,
      begin: null,
      end: null,
    },
    '2022-02-18': {
      statusZeroCount: 0,
      statusOneCount: 0,
      statusTwoCount: 0,
      statusThreeCount: 0,
      statusFourCount: 0,
      statusFiveCount: 0,
      begin: null,
      end: null,
    },
    '2022-02-14': {
      statusZeroCount: 0,
      statusOneCount: 0,
      statusTwoCount: 0,
      statusThreeCount: 0,
      statusFourCount: 0,
      statusFiveCount: 0,
      begin: null,
      end: null,
    },
  } as Data,
  headerData: {} as Data,
  productData: {} as Data,
  customerData: {} as Data,
  updateTime: '',
  lineTitleList: [
    { title: '待分配', color: '#13c2c2', isShow: true },
    { title: '评估中', color: '#9486fd', isShow: true },
    { title: '开发中', color: '#41a3fd', isShow: true },
    { title: '报价中', color: '#ff972f', isShow: true },
    { title: '已完成', color: '#425ed2', isShow: true },
    { title: '已终止', color: '#f96868', isShow: true },
  ],
  pieTitleList: [
    { title: '上市公司', color: '#13c2c2', isShow: true },
    { title: '非上市公司', color: '#ff972f', isShow: true },
    { title: 'IPO公司', color: '#41a3fd', isShow: true },
  ],
  // barTitleList: [
  //   { title: '填报信息数量', color: '#13c2c2', isShow: true },
  //   { title: '竞品数量', color: '#41a3fd', isShow: true },
  // ],
})
export default pageData
